<template>
  <div class="orderDetail">
    <van-nav-bar  left-arrow  :safe-area-inset-top="true" fixed :border="false" @click-left="goBack"
                  class="nav" >
      <template #title>
        <p class="title">订单详情</p>
      </template>


    </van-nav-bar>

    <div class="main-body" v-if="data.result">
      <div class="top">
        <van-image v-if=" data.result.number == null || data.result.number == ''" class="state-icon" :src="require('../../assets/mine/icon_order_wait.png')"/>
        <van-image v-else class="state-icon" :src="require('../../assets/mine/icon_order_finish.png')"/>
        <p v-if=" data.result.number == null || data.result.number == ''" class="state-desc">发放中</p>
        <p  v-else class="state-desc-success">订单已完成</p>

      </div>
      <div class="product-detail">
        <van-image radius="8px"  class="left-img" :src="data.result.image"/>
        <div class="product-detail-right">
          <p class="t-product">{{data.result.productName}}</p>
          <div class="div-number">
            <div class="left-chain-logo">
              <van-image :src="require('../../assets/mine/icon_mine_chain.png')" class="goods-bottom-img"  >
              </van-image>
            </div>
            <p class="ntf-number">{{  (data.result.number == null || data.result.number == '') ? '编码生成中' : data.result.number}}</p>
          </div>
          <p class="fx-desc">{{data.result.issuer}}</p>

        </div>

      </div>

      <div class="order-detail">

        <div class="singleOne">
          <p class="singleOne-left">
            订单数量
          </p>
          <p class="singleOne-right">
            {{data.result.num}}
          </p>
        </div>

        <div class="singleOne">
          <p class="singleOne-left">
            创建时间
          </p>
          <p class="singleOne-right">
            {{data.result.createTime}}
          </p>
        </div>

        <div class="singleOne">
          <p class="singleOne-left">
           领取时间
          </p>
          <p class="singleOne-right">
            {{data.result.payTime}}
          </p>
        </div>

        <div class="singleOne">
          <p class="singleOne-left">
            订单编号
          </p>
          <p class="singleOne-right">
            {{data.result.id}}
          </p>
        </div>

      </div>

      <div class="back-home">
        <div class="btn-back" @click="goToHome">返回首页</div>
      </div>

    </div>


  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { clearToast, showFailToast, showLoading } from "../../utils/util";
import {getCurrentInstance, reactive} from "vue";
import router from "../../router";
export default {
  name: "orderDetail",
  setup() {

    //埋点上报
    eshimin.bytedanceApplogEvent({
      eventName: 'page_detail', // 例子
      eventParams: {'page_name': '我的-订单详情','title_name': '我的-订单详情'} ,//例子
      onResponse:  (res)=> {
        console.log("bytedanceApplogEvent responseData:" + res);
      }
    })

    const { proxy } = getCurrentInstance();
    const $router = useRouter();

    let id = $router.currentRoute.value.query.orderId
    const data = reactive({
        id:id,
        result:null,
    })

    const loadData = () => {
      showLoading();
      proxy.$http.get("wenwu-user/order/info?id="+ data.id).then(res => {
        clearToast()
        if (res.data.code == 200) {

          data.result = res.data.data;
        } else {
          showFailToast(res.data.message)

        }
      }).catch((err)=>{
        clearToast()
        showFailToast(err.message)
      })
    }


    const goBack = (() => {
      $router.back(-1)
    })

    const goToHome = () => {
      $router.push('/index')
    }

    loadData()

    return {
      goBack,
      goToHome,
      data
    };
  }
};
</script>
<style scoped lang="scss" src="./index.scss">

</style>

